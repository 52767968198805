/**
 * 通用混合
 * */
import { mapState, mapMutations } from 'vuex'
export default {
    data(){
        return {
            storeList:[]
        }
    },
    computed: {
        ...mapState(['UserInfo','_storeList']),
        isAdmin(){
            return this.UserInfo.isAdmin
        }
    },
    mounted(){
        if(this.isAdmin){
            this.formList = [
                {
                    label: '选择门店',
                    type: 'select',
                    filterable: true,
                    model: 'orgId',
                    options: []
                },
                ...this.formList
            ]
            this.getStoreMenuList({})
        }else{
            this.formValidate.orgId = this.UserInfo.orgId
        }
        this.$refs.baseForm.setData(this.formValidate)
    },
    methods: {
        ...mapMutations(['SET_STORE_LIST']),
        getStoreMenuList(params = {}) {
            // if(this._storeList.length){
            //     this.formList.find(item=>item.model=='orgId').options = this._storeList
            // }else{ }
            this.$api.order.getStoreMenuList(params).then((res) => {
                console.log(res)
                this.storeList = res.map(v=>{
                    return {
                        label: v.storeName,
                        value: v.orgId
                    }
                })
                this.SET_STORE_LIST(this.storeList)
                this.formList.find(item=>item.model=='orgId').options = this.storeList
            })
            
        }
    }
}
