<template>
  <div>
    <div class="title">
      <div>店内订单明细</div>
      <div>
        <!-- <Button icon="md-download" type="text" class="md-download-btn">导出</Button> -->
        <Button type="text" class="md-download-btn" @click="updateAllSatus(true)">批量移入</Button>
        <Button type="text" class="md-download-btn" @click="updateAllSatus(false)">批量移回</Button>
        <Button type="text" class="md-download-btn" @click="open('showModal')">重新生成订单号</Button>
        <Button type="text" class="md-download-btn" @click="open('showModal2')">同步数据</Button>
      </div>
    </div>
    <div class="tab">
      <base-form ref="baseForm" :formList="formList" @search="search"></base-form>
    </div>
    <div class="table" ref="tableBox">
      <!-- <div class="Tabs">
        <Tabs v-model="tabsValue">
          <TabPane v-for="item in tabsList" :key="item.value" :label="item.label" :name="item.value"></TabPane>
      </Tabs>
      </div> -->
      <IviewTable
        :height="tableHeight"
        :border="true"
        :page="page"
        :columns="tableColumns"
        :data="tableData"
        :context="self"
        backStage
        show-summary
        :summary-method="handleSummary" 
        @on-change="pageChange"
        @on-page-size-change="pageSizeChange"
        @on-selection-change="onSelectionChange"
      ></IviewTable>
      <div class="amount-tips">
        <div>合计移入订单金额：</div>
        <div>{{ orderAmount }}元</div>
      </div>
    </div>

    <Modal
      v-model="showModal"
      title="重新生成订单号"
    >
    <div style="height: 200px;">
      <div >
        <form-row label="选择门店">
          <Select v-model="orgId" class="ipt" :disabled="disabled" clearable style="width: 300px">
            <Option v-for="item1 in storeList" :value="item1.value" :key="item1.label">{{ item1.label }}</Option>
          </Select>
        </form-row>
        <form-row label="日期范围">
          <DatePicker 
            v-model="dataRange"
            separator="至" 
            format="yyyy/MM/dd" 
            type="daterange" 
            placement="bottom-end" 
            placeholder="选择日期" 
            style="width: 300px"
            :disabled="disabled"
          ></DatePicker>
        </form-row>
        <form-row label="刷新时长(S)">
          <InputNumber :max="10" :min="1" v-model="timeDuration" :disabled="disabled"></InputNumber>
        </form-row>
        <form-row>
          <Button type="primary" class="btn-save" @click="reIndex" :disabled="disabled">生成</Button>
        </form-row>
      </div>

      <div v-if="disabled" style="padding-left:24px">
        <div>执行结果</div>
        <div>{{reIndexStatus}}</div>
      </div>
    </div>
      <div slot="footer">
        <Button type="primary" class="btn-save" @click="showModal=false">关闭</Button>
      </div>
    </Modal>

    <Modal
      v-model="showModal2"
      title="同步数据"
    >
      <div style="min-height: 200px;">
        <div >
          <form-row label="选择门店">
            <Select v-model="orgIds" class="ipt" @on-change="orgIdsChange" :disabled="disabled" multiple clearable style="width: 300px">
              <Option :value="0" :key="0">全部门店</Option>
              <Option v-for="item1 in storeList" :value="item1.value" :key="item1.label">{{ item1.label }}</Option>
            </Select>
          </form-row>
          <form-row label="日期范围">
            <DatePicker 
              v-model="dataRange"
              separator="至" 
              format="yyyy/MM/dd" 
              type="daterange" 
              placement="bottom-end" 
              placeholder="选择日期" 
              style="width: 300px"
              :disabled="disabled"
              @on-change="dateChange"
              :options="datePickerOptions"
            ></DatePicker>
          </form-row>
          <form-row label="刷新时长(S)">
            <InputNumber :max="10" :min="1" v-model="timeDuration" :disabled="disabled"></InputNumber>
          </form-row>
          <form-row>
            <Button type="primary" class="btn-save" @click="work" :disabled="disabled">同步</Button>
          </form-row>
        </div>

        <div v-if="disabled" style="padding-left:24px">
          <div>执行结果</div>
          <div>{{reIndexStatus}}</div>
        </div>
      </div>
      <div slot="footer">
        <Button type="primary" class="btn-save" @click="showModal2=false">关闭</Button>
      </div>
    </Modal>

    <Modal
      v-model="showModal3"
      :title="`${rowData.orderNo}-订单详情`"
      width="800"
      footer-hide
    >
      <food-detail :tableData="foodData"></food-detail>
    </Modal>
  </div>
</template>

<script>
import { formatTime } from "@/utils";
import IviewTable from '_c/iview/IviewTable'
import baseForm from '@/components/baseForm'
import FormRow from '@/components/formRow'
import FoodDetail from './food-detail.vue'
import mixin from '@/mixins'
import { mapState, mapMutations } from 'vuex'
let toYuan = (h, params, props) => {
  return h(
    'span',
    {
      props: {},
    },
    (params.row[props]/100).toFixed(2)
  )
}
export default {
  name: '',
  components: {
    baseForm,
    IviewTable,
    FormRow,
    FoodDetail
  },
  mixins: [mixin],
  data() {
    return {
      self: this,
      showModal:false,
      showModal2:false,
      showModal3:false,
      modalFormStatus: false,
      tableHeight:0,
      formValidate: {
        dataRange: [],
        income: null,
        orgId: ''
      },
      selection:[],
      tabsValue: "0",
      tabsList:[{
        label:"全部订单",
        value:"0"
      },{
        label:"待接单",
        value:"1"
      },{
        label:"待制作",
        value:"2"
      },{
        label:"未结账",
        value:"3"
      },{
        label:"已结账",
        value:"4"
      },{
        label:"已撤单",
        value:"5"
      },{
        label:"已退单",
        value:"6"
      }],
      formList: [
        {
          label: '',
          type: 'datePicker-daterange',
          model: 'dataRange',
        },
        {
          label: '订单收入(元)',
          placeholder: '订单收入(元)',
          type: 'input-number',
          model: 'income',
        },
      ],
      page: {
        current: 1,
        pageSize: 40,
        total: 0,
      },
      orgId:'',
      orgIds: [],
      dataRange:[],
      rowData: {},
      tableData: [],
      tableColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '门店名称',
          key: 'poiName',
          minWidth: 200
        },
        {
          title: '美团订单号',
          key: 'orderNo',
          width: '160px'
        },
        {
          title: '新订单号',
          key: 'newOrderCode',
          width: '190px'
        },
        {
          title: '下单时间',
          key: 'orderTimeStr',
          width: '160px'
        },
        {
          title: '订单金额(元)',
          key: 'amount',
          width: '100px',
          summary: true,
          render: (h, params) => toYuan(h, params, 'amount')
        },
        {
          title: '顾客应付(元)',
          key: 'receivable',
          width: '100px',
          summary: true,
          render: (h, params) => toYuan(h, params, 'receivable')
        },
        {
          title: '订单优惠(元)',
          key: 'discount',
          width: '100px',
          summary: true,
          render: (h, params) => toYuan(h, params, 'discount')
        },
        {
          title: '订单收入(元)',
          key: 'income',
          width: '100px',
          summary: true,
          render: (h, params) => toYuan(h, params, 'income')
        },
        {
          title: '是否开票',
          key: 'hasInvoice',
          width: '100px',
          summary: true,
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  style: params.row.hasInvoice ? { color: '#19be6b' } : { color: '#ed4014' },
                },
                params.row.hasInvoice ? '已开票' : '未开票'
              ),
            ])
          }
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          align: 'center',
          width: 180,
          render: (h, params) => {
            return h('div', [
              h(
                'Button',
                {
                  style: {marginRight: '10px'},
                  props: {
                    type: params.row.isChecked ? 'error' : 'text',
                    size: 'small'
                  },
                  on: {
                    click: () => {
                      this.updateSatus(params.row)
                    },
                  },
                },
                params.row.isChecked ? '移回' : '移入'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'primary',
                    size: 'small'
                  },
                  on: {
                    click: () => {
                      this.showOrderDetail(params.row)
                    },
                  },
                },
                '订单详情'
              )
            ])
          },
        }
      ],

      timer: null,
      timeDuration: 2,
      reIndexStatus:'待开始',
      disabled:false,
      datePickerOptions:{
        disabledDate (date) {
          return date && date.valueOf() > Date.now();
        }
      },
      orderAmount: 0,
      foodData: []
      
    }
  },
  computed: {
    ...mapState(['UserInfo']),
    showStoreList(){
      return this.UserInfo.isAdmin
    }
  },
  mounted() {
    this.getElementHeight();
    // 监听页面rezise事件
    window.addEventListener("resize", this.getElementHeight);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("resize", this.getElementHeight);
    });
  },
  methods: {
    ...mapMutations(['SET_ORG_ID']),
    orgIdsChange(val){
      console.log(val)
      if (val.includes(0)) {
        this.orgIds = [0]
      }
    },
    dateChange(date,date2){
      console.log('dateChange',date,date2)
      let [s,e] = date
      function getTime (date){ return new Date(date).getTime() }
      const day = Math.floor((getTime(e) - getTime(s)) / 86400000)
      if (day > 20) {
        this.dataRange = ['', '']
        return this.$Message.error('日期范围最大选择20天')
      }
    },
    work(){
      if(this.timer){
        clearTimeout(this.timer)
      }
      this.timer = null

      if(!this.orgIds || this.orgIds.length === 0){
        this.$Message.warning('请选择要同步的门店');
        return
      }
      if(!this.dataRange || !this.dataRange.length==2 || !this.dataRange[0] || !this.dataRange[1]){
        this.$Message.warning('请选择要生成的日期范围');
        return
      }
      let params = { }
      params.start = formatTime(this.dataRange[0])[1]
      params.end = formatTime(this.dataRange[1])[1]
      params.orgId = this.orgIds.join(',')
      console.log(params)
      this.$api.order.work(params).then(res=>{
        // this.$Message.warning('正在同步，请稍后查看！');
        if(res=='完成'){
          if(this.disabled){
            this.$Message.success('同步数据执行完成！')
            this.disabled = false
          }else{
            // 上次刚好完成，直接请求这次的
            this.work()
          }
        }else{
          if(!this.disabled && res!="开始同步"){
            // 上一次
            this.$Message.warning('上次请求还未执行完成，请稍候...')
          }
          this.disabled = true
          this.reIndexStatus = res
          this.timer = setTimeout(() => {
            this.work()
          }, this.timeDuration*1000);
        }

      })
    },
    open(prop){
      this[prop] = true
      if(this.timer){
        clearTimeout(this.timer)
      }
      this.timer = null
      this.disabled = false
    },
    reIndex(){
      if(this.timer){
        clearTimeout(this.timer)
      }
      this.timer = null
      let params = {
        "IsChecked":true
      }
      if(!this.orgId){
        this.$Message.warning('请选择要生成的门店');
        return
      }
      if(!this.dataRange || !this.dataRange.length==2 || !this.dataRange[0] || !this.dataRange[1]){
        this.$Message.warning('请选择要生成的日期范围');
        return
      }
      if(this.timeDuration<1){
        this.$Message.warning('请设置刷新时长');
        return
      }
      params.orgId = this.orgId
      params.start = formatTime(this.dataRange[0])[1]
      params.end = formatTime(this.dataRange[1])[1]
      console.log(params)
      this.$api.order.reIndex(params).then(res=>{
        console.log(res)
        if(res=='完成'){
          if(this.disabled){
            this.$Message.success('生成订单号执行完成！')
            this.disabled = false
          }else{
            // 上次刚好完成，直接请求这次的
            this.reIndex()
          }
        }else{
          if(!this.disabled && res!="开始"){
            // 上一次
            this.$Message.warning('上次请求还未执行完成，请稍候...')
          }
          this.disabled = true
          this.reIndexStatus = res
          this.timer = setTimeout(() => {
            this.reIndex()
          }, this.timeDuration*1000);
        }
      })
    },
    getElementHeight() {
      const element = this.$refs.tableBox;
      const height = element.offsetHeight; // 或者使用其他的属性，如clientHeight、scrollHeight等
      console.log('Element height:', height);
      // 其他逻辑处理
      this.tableHeight = height - 90
    },
    handleSummary ({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        console.log(column)
          const key = column.key;
          if (index === 1) {
              sums[key] = {
                key,
                value: '选中项合计'
              };
              return;
          }
          if (!column.summary) {
              sums[key] = {
                  key,
                  value: ''
              };
              return;
          }
          const values = data.filter(v=>this.selection.includes(v.orderNo)).map(item => Number(item[key]));
          if (!values.every(value => isNaN(value)) && values.length>0) {
              const v = values.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                      return prev + curr;
                  } else {
                      return prev;
                  }
              }, 0);
              sums[key] = {
                key,
                value: (v/100).toFixed(2)
              };
          } else {
              sums[key] = {
                  key,
                  value: '0.00'
              };
          }
      });
      return sums;
    },
    onSelectionChange(selection){
      this.selection = selection.map(v=>v.orderNo)
      console.log(selection)
    },
    pageChange(page){
      this.page.current = page
      this.formValidate.page = page
      this.getOrderList(this.formValidate)
    },
    pageSizeChange(pageSize){
      this.page.current = 1
      this.page.pageSize = pageSize
      this.formValidate.page = 1
      this.formValidate.pageSize = pageSize
      this.getOrderList(this.formValidate)
    },
    search(data,close) {
      this.page.current = 1
      let params = {
        ...data,
        page: this.page.current,
        pageSize: this.page.pageSize,
      }
      if(close){
        this.page.total = 0
        this.tableData = []
        return
      }
      if(this.isAdmin && (!data || !data.orgId)){
        this.$Message.warning('请选择要查询的门店');
      }else if(!data || !data.dataRange || data.dataRange.length!==2 || !data.dataRange[0] || !data.dataRange[1]){
        this.$Message.warning('请选择要查询的日期');
      } else {
        params.start = formatTime(data.dataRange[0])[1]
        params.end = formatTime(data.dataRange[1])[1]
        console.log(params)
        this.formValidate = params
        this.getOrderList(params)
      }
    },
    getOrderList(params = {}) {
      params.showAll = true
      if(params.income){
        params.income = Number((params.income * 100).toFixed(2))
      }else{
        delete params.income
      }
      delete params.dataRange
      if (!params.start || !params.end) {
        return
      }
      this.$api.order.getOrderList(params).then((res) => {
        console.log(res)
        this.selection = []
        this.tableData = res.items.map((item) => {
          return {
            ...item.orderBase,
            orderTimeStr: formatTime(new Date(item.orderBase.orderTime))[0],
            newOrderCode: item.newOrderCode,
            orderId: item.orderId,
            isChecked: item.isChecked
          }
        })
        this.page.total = res.count
      })
      if(params.page==1){
        this.initOrderAmount()
      }
    },
    updateSatus(row){
      this.$Modal.confirm({
        title: '修改状态',
        content: !row.isChecked
          ? '<p>是否确定移入订单</p>'
          : '<p>是否确定移回订单</p>',
        onOk: () => {
          row.isChecked = !row.isChecked
          let data = {
            "IsChecked": row.isChecked,
            "Items": [row.orderNo]
          }
          this.$api.order.updateOrderShowStatus(data).then((res) => {
            console.log(res)
            this.getOrderList(this.formValidate)
          })
        },
        onCancel: () => {
          // this.$Message.info('点击了取消');
        },
      })
    },
    updateAllSatus(status){
      if(this.selection.length==0){
        this.$Message.warning('请选择要操作的订单');
        return
      }
      this.$Modal.confirm({
        title: '修改状态',
        content: status
          ? '<p>是否确定移入选中的订单</p>'
          : '<p>是否确定移回选中的订单</p>',
        onOk: () => {
          let data = {
            "IsChecked": status,
            "Items": this.selection
          }
          this.$api.order.updateOrderShowStatus(data).then((res) => {
            console.log(res)
            this.getOrderList(this.formValidate)
            this.initOrderAmount()
          })
        },
        onCancel: () => {
          // this.$Message.info('点击了取消');
        },
      })

    },
    // 合计移入金额
    initOrderAmount(){
      let { start, end } = this.formValidate
      let params = {
        start,
        end
      }
      this.$api.order.getOrderGroup(params).then((res) => {
        let amount = 0
        Object.keys(res).map((key) => {
          amount += res[key]
        })
        this.orderAmount = (amount/100).toFixed(2)
        // this.page.total = res.count
      })
    },
    // 订单详情
    showOrderDetail(row){
      this.rowData = row
      let { orderId } = row
      this.showModal3 = true
      this.foodData = []
      this.$api.order.getOrderDetail({ orderId }).then(res=>{
        this.foodData = res
      })
    }
  },
}
</script>

<style lang="less" scoped>
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 28px;
  font-size: 24px;
  .md-download-btn {
    font-size: 16px;
  }
}
.tab {
  background: #ffffff;
}
.table {
  background: #ffffff;
  padding: 0 30px;
  position: relative;
  height: calc(100vh - 155px);
  /deep/.i-page.fixed {
    position: absolute;
    bottom: -40px;
  }
  .btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
// .btn-save {
//   width: 80px;
//   height: 30px;
//   background: #85c989;
//   box-shadow: 0px 2px 2px 0px #448247, 0px 0px 1px 0px #b4ffb9;
//   font-size: 14px;
//   font-weight: bold;
//   color: #ffffff;
//   text-shadow: 0px 1px 1px #163124, 0px 3px 5px #bcffca;
//   margin-right: 11px;
//   border: none;
// }

.flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.amount-tips {
  display: flex;
  align-items: center;
  height: 45px;
  font-weight: bold;
  font-size: 18px;
}
</style>
